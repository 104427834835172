header, main {
  width: 100%;
  padding: 1rem 1rem 0;
}

@media (width >= 1024px) {
  header, main {
    max-width: 70vw;
  }
}

header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  display: flex;
  position: relative;
}

@media (width >= 1024px) {
  header {
    justify-content: flex-start;
  }
}

header h1 {
  letter-spacing: -3px;
  margin: 0;
  font-family: Fira Code, monospace;
  font-weight: 100;
}

header h1 a {
  align-items: flex-start;
  display: flex;
}

header h1 i {
  color: #302513;
  letter-spacing: -6px;
  margin-left: -6px;
  line-height: 27px;
  transition: all .5s ease-in-out;
}

header h1 a:link, header h1 a:visited {
  color: #2b6438;
  text-decoration: none;
  transition: color 1s ease-in-out;
}

header h1 a:link:focus-visible, header h1 a:visited:focus-visible {
  text-shadow: -1px -1px #9acd32, 1px -1px #9acd32, -1px 1px #9acd32, 1px 1px #9acd32;
  outline: none;
}

header h1 a:link:hover, header h1 a:link:active, header h1 a:visited:hover, header h1 a:visited:active {
  color: #302513;
}

header h1 a:link:hover i, header h1 a:link:active i, header h1 a:visited:hover i, header h1 a:visited:active i {
  color: #d0de21cc;
  animation: 3s ease-in-out 1s infinite rainbow;
}

header nav > ul {
  z-index: 1;
  background-color: #e3e3e3;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  width: 100%;
  margin: 0;
  padding: 1rem 0 0 1rem;
  list-style-type: none;
  display: none;
  position: absolute;
  left: 0;
  box-shadow: 0 10px 8px #0003;
}

@media (width >= 1024px) {
  header nav > ul {
    box-shadow: none;
    flex-direction: row;
    width: auto;
    padding: 0;
    display: flex;
    position: static;
  }

  header nav > ul ul {
    opacity: 0;
    pointer-events: none;
    z-index: 1;
    background-color: #e3e3e3;
    border-radius: 0 0 .5rem .5rem;
    flex-direction: column;
    min-width: 16rem;
    transition: opacity .15s ease-in-out;
    display: flex;
    position: absolute;
    left: -1rem;
    box-shadow: 0 10px 8px #0003;
  }

  header nav > ul > li {
    position: relative;
  }

  header nav > ul > li:hover ul, header nav > ul > li:focus-within ul {
    opacity: 1;
    pointer-events: initial;
  }

  header nav > ul li > strong {
    cursor: default;
  }
}

header nav > ul ul {
  margin: 0;
  padding: 1rem 0 0;
  list-style-type: none;
}

header nav > ul ul li {
  align-items: stretch;
  display: flex;
}

@media (width >= 1024px) {
  header nav > ul ul li:last-of-type a {
    border-radius: 0 0 .5rem .5rem;
  }
}

header nav > ul ul li.experimental a {
  background-image: url("data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgcm9sZT0iaW1nIj48cGF0aCBkPSJNOTAuNzIgODIuMzRjNC40IDcgMS4yOSAxMi42Ni03IDEyLjY2SDE2LjI1QzggOTUgNC44OCA4OS4zMSA5LjI4IDgyLjM0bDI5LjQ3LTQ2LjQ2VjEyLjVIMzVBMy43NSAzLjc1IDAgMDEzNSA1aDMwYTMuNzUgMy43NSAwIDAxMCA3LjVoLTMuNzV2MjMuMzh6TTQ1LjA4IDM5Ljg2TDI5LjE0IDY1aDQxLjcyTDU0LjkyIDM5Ljg2bC0xLjE3LTEuODFWMTIuNWgtNy41djI1LjU1eiIgZmlsbD0iY3VycmVudENvbG9yIi8+PC9zdmc+");
  background-position: right 16px center;
  background-repeat: no-repeat;
  background-size: 16px;
}

header nav > ul ul a:link, header nav > ul ul a:visited {
  color: #333;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 1rem;
  text-decoration: none;
  display: flex;
}

@media (width >= 1024px) {
  header nav > ul ul a:link, header nav > ul ul a:visited {
    padding: .5rem 1rem;
  }
}

header nav > ul ul a:link.active, header nav > ul ul a:link:active, header nav > ul ul a:visited.active, header nav > ul ul a:visited:active {
  background-color: #9acd32;
}

header nav > ul ul a:link:hover, header nav > ul ul a:link:focus, header nav > ul ul a:visited:hover, header nav > ul ul a:visited:focus {
  color: #fff;
  background-color: green;
  outline: none;
}

header nav > ul ul a:link.pending, header nav > ul ul a:visited.pending {
  cursor: wait;
}

header nav.open .hamburger {
  transform: rotate(-135deg);
}

header nav.open > ul {
  display: flex;
}

header .hamburger {
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  padding: 0;
  font-size: 2rem;
  transition: transform .15s ease-in;
  display: flex;
}

@media (width >= 1024px) {
  header .hamburger {
    display: none;
  }
}

.version {
  opacity: .5;
  position: fixed;
  bottom: 8px;
  left: 8px;
}

.github {
  transform-origin: 50%;
  background-color: #333;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 32px;
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
  transform: rotate(-45deg)translate(60px, 50px);
}

.github svg {
  color: #fff;
}

.github:hover, .github:active {
  background-color: #666;
}

@keyframes rainbow {
  0% {
    color: #d0de21cc;
  }

  10% {
    color: #4fdc4acc;
  }

  20% {
    color: #3fdad8cc;
  }

  30% {
    color: #2fc9e2cc;
  }

  40% {
    color: #1c7feecc;
  }

  50% {
    color: #5f15f2cc;
  }

  60% {
    color: #ba0cf8cc;
  }

  70% {
    color: #fb07d9cc;
  }

  80% {
    color: #f00c;
  }

  90% {
    color: #f00c;
  }

  100% {
    color: #ff9a00cc;
  }
}
/*# sourceMappingURL=index.991ddec9.css.map */
