header,
main {
  width: 100%;
  padding: 1rem 1rem 0;

  @media (min-width: 1024px) {
    max-width: 70vw;
  }
}

header {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  @media (min-width: 1024px) {
    justify-content: flex-start;
  }

  h1 {
    margin: 0;
    font-family: 'Fira Code', monospace;
    font-weight: 100;
    letter-spacing: -3px;

    a {
      display: flex;
      align-items: flex-start;
    }

    i {
      color: #302513;
      line-height: 27px;
      letter-spacing: -6px;
      margin-left: -6px;
      transition: all 0.5s ease-in-out;
    }

    a:link,
    a:visited {
      color: #2b6438;
      text-decoration: none;
      transition: color 1s ease-in-out;

      &:focus-visible {
        outline: none;
        text-shadow: -1px -1px 0 yellowgreen, 1px -1px 0 yellowgreen,
          -1px 1px 0 yellowgreen, 1px 1px 0 yellowgreen;
      }

      &:hover,
      &:active {
        color: #302513;

        i {
          color: rgba(208, 222, 33, 0.8);
          animation: rainbow 3s ease-in-out 1s infinite;
        }
      }
    }
  }

  nav > ul {
    display: none;
    align-items: flex-start;
    gap: 1rem;
    margin: 0;
    padding: 1rem 0 0 1rem;
    list-style-type: none;
    position: absolute;
    left: 0;
    z-index: 1;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    background-color: #e3e3e3;
    box-shadow: 0 10px 8px #00000033;

    @media (min-width: 1024px) {
      display: flex;
      position: static;
      width: auto;
      flex-direction: row;
      box-shadow: none;
      padding: 0;

      ul {
        display: flex;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        left: -1rem;
        min-width: 16rem;
        z-index: 1;
        flex-direction: column;
        background-color: #e3e3e3;
        box-shadow: 0 10px 8px #00000033;
        transition: opacity 0.15s ease-in-out;
        border-radius: 0 0 0.5rem 0.5rem;
      }

      > li {
        position: relative;

        &:hover,
        &:focus-within {
          ul {
            opacity: 1;
            pointer-events: initial;
          }
        }
      }

      li > strong {
        cursor: default;
      }
    }

    ul {
      margin: 0;
      padding: 1rem 0 0;
      list-style-type: none;

      li {
        display: flex;
        align-items: stretch;

        @media (min-width: 1024px) {
          &:last-of-type a {
            border-radius: 0 0 0.5rem 0.5rem;
          }
        }

        &.experimental a {
          background-image: url('data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgcm9sZT0iaW1nIj48cGF0aCBkPSJNOTAuNzIgODIuMzRjNC40IDcgMS4yOSAxMi42Ni03IDEyLjY2SDE2LjI1QzggOTUgNC44OCA4OS4zMSA5LjI4IDgyLjM0bDI5LjQ3LTQ2LjQ2VjEyLjVIMzVBMy43NSAzLjc1IDAgMDEzNSA1aDMwYTMuNzUgMy43NSAwIDAxMCA3LjVoLTMuNzV2MjMuMzh6TTQ1LjA4IDM5Ljg2TDI5LjE0IDY1aDQxLjcyTDU0LjkyIDM5Ljg2bC0xLjE3LTEuODFWMTIuNWgtNy41djI1LjU1eiIgZmlsbD0iY3VycmVudENvbG9yIi8+PC9zdmc+');
          background-repeat: no-repeat;
          background-position: right 16px center;
          background-size: 16px;
        }
      }

      a:link,
      a:visited {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 1rem;
        text-decoration: none;
        color: #333;

        @media (min-width: 1024px) {
          padding: 0.5rem 1rem;
        }

        &.active,
        &:active {
          background-color: yellowgreen;
        }

        &:hover,
        &:focus {
          background-color: green;
          color: #fff;
          outline: none;
        }

        &.pending {
          cursor: wait;
        }
      }
    }
  }

  nav.open {
    .hamburger {
      transform: rotate(-135deg);
    }

    > ul {
      display: flex;
    }
  }

  .hamburger {
    background: none;
    border: none;
    padding: 0;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    transition: transform 0.15s ease-in;

    @media (min-width: 1024px) {
      display: none;
    }
  }
}

.version {
  position: fixed;
  bottom: 8px;
  left: 8px;
  opacity: 0.5;
}

.github {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 200px;
  height: 32px;
  transform: rotate(-45deg) translate(60px, 50px);
  transform-origin: 50%;
  background-color: #333;

  svg {
    color: #fff;
  }

  &:hover,
  &:active {
    background-color: #666;
  }
}

@keyframes rainbow {
  0% {
    color: rgba(208, 222, 33, 0.8);
  }
  10% {
    color: rgba(79, 220, 74, 0.8);
  }
  20% {
    color: rgba(63, 218, 216, 0.8);
  }
  30% {
    color: rgba(47, 201, 226, 0.8);
  }
  40% {
    color: rgba(28, 127, 238, 0.8);
  }
  50% {
    color: rgba(95, 21, 242, 0.8);
  }
  60% {
    color: rgba(186, 12, 248, 0.8);
  }
  70% {
    color: rgba(251, 7, 217, 0.8);
  }
  80% {
    color: rgba(255, 0, 0, 0.8);
  }
  90% {
    color: rgba(255, 0, 0, 0.8);
  }
  100% {
    color: rgba(255, 154, 0, 0.8);
  }
}
